import styles from "./pageBottom.module.css";
import { IoMdMail } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

const PageBottom = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div>
            <p>
              <span className={styles.companyName}>RVSM Solutions Pvt Ltd</span>
              <br />
              <span className={styles.teleCom}>
                A telecommunication company
              </span>
            </p>
            <div style={{ marginTop: "35px" }}>
              <span
                onClick={() =>
                  window.open(
                    `https://linkedin.com/in/ram-sewak-6179881b4/`,
                    "_blank"
                  )
                }
              >
                <FaLinkedin fontSize="38px" style={{ cursor: "pointer" }} />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span
                onClick={() =>
                  window.open(
                    `https://mail.google.com/mail/?view=cm&fs=1&to=rvsmsolutionspvtltd@gmail.com`,
                    "_blank"
                  )
                }
              >
                <IoMdMail fontSize="40px" style={{ cursor: "pointer" }} />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/people/RVSM-Solutions-Pvt-Ltd/100092300852864/`,
                    "_blank"
                  )
                }
              >
                <FaFacebookSquare
                  fontSize="40px"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
          </div>
          <div>
            <h2>Contact Us</h2>
            <span>
              <h3>Address :</h3>
              LG-67, BalajiTower-5, Mahal Road, Jagatpura, Jaipur-302017
              <h3>Mail :</h3>rvsmsolutionspvtltd@gmail.com
              <h3>Phone :</h3>+91 9785329947, +0141-2945700
            </span>
          </div>
        </div>
        <div id={styles.legalRights}>
          <p>©RVSM Solutions Pvt Ltd . All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default PageBottom;
