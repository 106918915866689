import { createContext, useEffect, useState } from "react";
import { useAuth } from "../custom hooks/useAuth";

export const planContext = createContext();

export const CustomPlanContext = ({ children }) => {
  const [getPlans, setGetPlans] = useState([]);
  const [speedWiseData, setSpeedWiseData] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [getPlansError, setGetPlansError] = useState(null);
  const [getGeneralDataError, setGetGeneralDataError] = useState();
  const { token } = useAuth();

  const getPartners = () => {
    let partnersSet = new Set();
    let speedSet = new Set();

    getPlans.forEach((plan) => {
      partnersSet.add(plan.partner._id);
      speedSet.add(plan.speed);
    });

    let partnerIdArr = [...partnersSet];
    let speedArr = [...speedSet];

    const sortedSpeedArr = speedArr.sort((a, b) => {
      // Extract the numerical part of the strings
      let numA = parseInt(a.split(" ")[0]);
      let numB = parseInt(b.split(" ")[0]);

      return numA - numB;
    });
    let partnersArr = [];
    let speedWiseArr = [];
    partnerIdArr.forEach((id) => {
      const result = getPlans.filter((plan) => plan.partner._id === id);
      partnersArr.push(result);
    });

    partnersArr.forEach((partner) => {
      let partnerWise = [];
      sortedSpeedArr.forEach((speed) => {
        const result = partner.filter((plan) => plan.speed === speed);
        if (result.length > 0) {
          partnerWise.push(result);
        }
      });
      speedWiseArr.push(partnerWise);
    });

    setSpeedWiseData(speedWiseArr);
    return speedWiseArr;
  };

  const getPlans_DB = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/plans/getAll-plans`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        setGetPlans(responseData);
      } else {
        setGetPlans([]);
      }
      return response;
    } catch (err) {
      setGetPlansError(err.message);
    }
  };

  const getGeneralData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/general/getAll-generalChanges`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        setGeneralData(responseData);
      } else {
        setGeneralData([]);
      }
      return response;
    } catch (err) {
      setGetGeneralDataError(err.message);
    }
  };

  useEffect(() => {
    if (token) {
      (async function () {
        await getPlans_DB();
        await getGeneralData();
      })();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      if (getPlans.length > 0) {
        getPartners();
      }
    }
  }, [getPlans]);

  return (
    <>
      <planContext.Provider
        value={{
          speedWiseData,
          getPlans_DB,
          generalData,
          getGeneralData,
          getPartners,
          setSpeedWiseData,
        }}
      >
        {children}
      </planContext.Provider>
    </>
  );
};
