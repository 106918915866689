import { useEffect, useState } from "react";
import { useAuth } from "../../custom hooks/useAuth";
import ConvertToExcel from "../utility/Convert to Excel/convertToExcel";
import styles from "./customerDetails.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import verifyToken from "../utility/verify token/verifyToken";

const CustomerDetails = () => {
  const navigate = useNavigate();

  const [excelData, setExcelData] = useState([]);

  const [specificDateExcelData, setSpecificDateExcelData] = useState([]);
  const [excelDataError, setExcelDataError] = useState(null);
  const [specificDate, setSpecificDate] = useState("");
  const [multipleDate, setMultipleDate] = useState("");
  const [dateRange, setDateRange] = useState({
    date1: "",
    date2: "",
  });
  const { token, logoutUser } = useAuth();

  useEffect(() => {
    (async function () {
      const response = await verifyToken(token, logoutUser);
      if (response) {
        if (!response.ok) {
          navigate("/");
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_PATH}/api/customers/customer-data`,
          {
            method: "GET", // Method can be 'GET', 'POST', etc.
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();
        if (response.ok) {
          const transformedDataForExcel = responseData.map((customer, i) => {
           
            return {
              ...customer,
              _id: i + 1,
            };
          });

          setExcelData(transformedDataForExcel);
        } else {
          toast.error(responseData.message);
        }
      } catch (err) {
        setExcelDataError(err.message);
      }
    })();
  }, []);

  const handleSubmit = () => {
    ConvertToExcel(excelData);
  };

  const handleSpecificDate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/customers/specific-customer-data`,
        {
          method: "POST", // Method can be 'GET', 'POST', etc.
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ specificDate }),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        const transformedDataForExcel = responseData.map((customer, i) => {
          return {
            ...customer,
            _id: i + 1,
          };
        });
        setSpecificDateExcelData(transformedDataForExcel);
        toast.success("request sent successfully");
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      setExcelDataError(err.message);
    }
  };
  const downloadSpecificDate = () => {
    ConvertToExcel(specificDateExcelData);
  };

  const handleBWDates = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/customers/multipleDate-customer-data`,
        {
          method: "POST", // Method can be 'GET', 'POST', etc.
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dateRange),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        const transformedDataForExcel = responseData.map((customer, i) => {
          return {
            ...customer,
            _id: i + 1,
          };
        });

        setMultipleDate(transformedDataForExcel);
        toast.success("Request sent successfully");
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      setExcelDataError(err.message);
    }
  };

  const downloadMultiDate = () => {
    ConvertToExcel(multipleDate);
  };

  return (
    <>
      <div className={styles.container}>
        <h1>Download Customer Enquiry Details</h1>
        <div id={styles.AllTime}>
          <button onClick={handleSubmit}>All Time Customer Data</button>
        </div>
        <div className={styles.otherData}>
          <input
            type="date"
            name="specificDate"
            value={specificDate}
            onChange={(e) => {
              setSpecificDate(e.target.value);
            }}
            className={styles.inputDiv}
          />
          <button onClick={handleSpecificDate}>Send Request</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={downloadSpecificDate}>
            Specific Date Customer Data
          </button>
        </div>
        <div className={styles.otherData}>
          <input
            type="date"
            name="dateRange1"
            value={dateRange.date1}
            onChange={(e) =>
              setDateRange({ ...dateRange, date1: e.target.value })
            }
            className={styles.inputDiv}
          />
          <input
            type="date"
            name="dateRange2"
            value={dateRange.date2}
            onChange={(e) =>
              setDateRange({ ...dateRange, date2: e.target.value })
            }
            className={styles.inputDiv}
          />
          <button onClick={handleBWDates}>Send Request</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={downloadMultiDate}>
            Customer Data B/W Two Dates
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
