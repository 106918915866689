const XLSX = require("xlsx");
const ConvertToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Customer Details");
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Id",
        "Name",
        "Email Address",
        "Phone No.",
        "Address",
        "City",
        "State",
        "Speed",
        "Plan Duration",
        "Price",
        "Partner",
        "Date",
      ],
    ],
    { origin: "A1" }
  );

  const columnWidths = [
    { wch: data.reduce((w, r) => Math.max(w, r._id.length), 5) }, // Width of column A (1st column)
    { wch: data.reduce((w, r) => Math.max(w, r.name.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.email.length), 5) }, // Width of column B (2nd column)
    { wch: data.reduce((w, r) => Math.max(w, toString(r.phone).length), 5) }, // Width of column C (3rd column)
    { wch: data.reduce((w, r) => Math.max(w, r.address.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.city.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.state.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.broadbandSpeed.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.planDuration.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.planPrice.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.partner.length), 5) },
    { wch: data.reduce((w, r) => Math.max(w, r.createdAt.length), 5) },
  ];
  worksheet["!cols"] = columnWidths;
  XLSX.writeFile(workbook, "Customer Details.xlsx", { compression: true });
};

export default ConvertToExcel;
