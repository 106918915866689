import Signin from "./components/loginPage/signin";
import Home from "./components/home/home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import ErrorPage from "./components/errorPage/error";
import LogoutUser from "./components/logoutUser/logoutUser";
import AddPlan from "./components/broadbandPlans/add plan/addPlan";
import GeneralChange from "./components/broadbandPlans/general Changes/generalChanges";
import AddPartner from "./components/broadbandPlans/add Partner/addPartner";
import GetAllPlans from "./components/broadbandPlans/getAllPlans/getAllPlans";
import CustomerDetails from "./components/customerDetails/customerDetails";
import SignOutPage from "./components/errorPage/signoutPage";
import LoggedIn from "./components/errorPage/loggedIn";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorPage />,
      element: (
        <LoggedIn>
          <Signin />
        </LoggedIn>
      ),
    },
    {
      path: "/admin",
      element: <Navbar />,
      children: [
        {
          path: "home",
          element: (
            <SignOutPage>
              <Home />
            </SignOutPage>
          ),
        },
        {
          path: "customer-details",
          element: (
            <SignOutPage>
              <CustomerDetails />
            </SignOutPage>
          ),
        },
        {
          path: "broadband-plans",
          element: (
            <SignOutPage>
              <AddPartner />
            </SignOutPage>
          ),
        },
        {
          path: "add-plans",
          element: (
            <SignOutPage>
              <AddPlan />
            </SignOutPage>
          ),
        },
        {
          path: "getAll-plans",
          element: (
            <SignOutPage>
              <GetAllPlans />
            </SignOutPage>
          ),
        },
        {
          path: "general-plans",
          element: (
            <SignOutPage>
              {" "}
              <GeneralChange />
            </SignOutPage>
          ),
        },
        {
          path: "logout",
          element: <LogoutUser />,
        },
        { path: "*", element: <ErrorPage /> },
      ],
    },
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
