import styles from "./getAllPartners.module.css";

const GetAllPartners = ({ allPartners, handleDelete }) => {
  return (
    <>
      <div className={styles.container}>
        <h1>
          All Broadband Partners <hr />
        </h1>

        <table className={styles.table}>
          <thead className={styles.tablehead}>
            <tr>
              <th>S.No.</th>
              <th style={{ width: "70%" }}>Partner Name</th>
              <th>Remove Partner</th>
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {allPartners.map((part, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{part.partner.toUpperCase()}</td>
                <td>
                  <button
                    id={styles.remove}
                    onClick={(e) => handleDelete(e, part.partner, part._id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GetAllPartners;
