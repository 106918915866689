import { Navigate } from "react-router-dom";
import { useAuth } from "../../custom hooks/useAuth";

const LoggedIn = ({ children }) => {
  const { token } = useAuth();
  if (token) {
    return <Navigate to="/admin/home" />;
  } else {
    return children;
  }
};

export default LoggedIn;
