import styles from "./navbar.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "../../static/images/logo/Logo-Removebg-RVSM.png";
import { GrLogout } from "react-icons/gr";
import { useAuth } from "../../custom hooks/useAuth";
import PageBottom from "../PageBottom/pageBottom";
import { useState } from "react";
import { VscThreeBars } from "react-icons/vsc";

const Navbar = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/admin/home");
  };

  const [menuToggle, setMenuToggle] = useState(false);

  const handleMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.navbar}`}>
          <img
            src={logo}
            alt="logo"
            className={styles.logo}
            onClick={() => {
              handleLogoClick();
            }}
          />
          <ul className={styles.navbarList}>
            {isLoggedIn ? (
              <>
                <li className={styles.navbarItem}>
                  <NavLink
                    to="/admin/home"
                    className={({ isActive }) => {
                      if (isActive) {
                        return styles.activeClass;
                      }
                      return "";
                    }}
                  >
                    Home
                  </NavLink>
                </li>

                <li className={styles.navbarItem}>
                  <NavLink
                    to="/admin/customer-details"
                    className={({ isActive }) => {
                      if (isActive) {
                        return styles.activeClass;
                      }
                      return "";
                    }}
                  >
                    CustomerDetails
                  </NavLink>
                </li>

                <li className={styles.navbarItem}>
                  <NavLink
                    to="/admin/broadband-plans"
                    className={({ isActive }) => {
                      if (isActive) {
                        return styles.activeClass;
                      }
                      return "";
                    }}
                  >
                    BroadbandPartners
                  </NavLink>
                </li>
                <li className={styles.navbarItem}>
                  <NavLink
                    to="/admin/add-plans"
                    className={({ isActive }) => {
                      if (isActive) {
                        return styles.activeClass;
                      }
                      return "";
                    }}
                  >
                    AddNewPlan
                  </NavLink>
                </li>
                <li className={styles.navbarItem}>
                  <NavLink
                    to="/admin/getAll-plans"
                    className={({ isActive }) => {
                      if (isActive) {
                        return styles.activeClass;
                      }
                      return "";
                    }}
                  >
                    GetAllPlans
                  </NavLink>
                </li>
                <li className={styles.navbarItem}>
                  <NavLink
                    to="/admin/general-plans"
                    className={({ isActive }) => {
                      if (isActive) {
                        return styles.activeClass;
                      }
                      return "";
                    }}
                  >
                    GeneralSettings
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>

          {isLoggedIn && (
            <div className={styles.logout}>
              <NavLink
                to="/admin/logout"
                className={({ isActive }) => {
                  if (isActive) {
                    return styles.activeClass;
                  }
                  return "";
                }}
              >
                <GrLogout />
                Logout
              </NavLink>
            </div>
          )}
        </div>
        <div className={styles.navbarSm}>
          <div className={styles.header}>
            <img
              src={logo}
              alt="logo"
              className={styles.logo}
              onClick={() => {
                handleLogoClick();
                setMenuToggle(false);
              }}
            />
            <div
              onClick={handleMenuToggle}
              style={{ cursor: "pointer" }}
              className={styles.threeBar}
            >
              Menu
              <div>
                <VscThreeBars />
              </div>
            </div>
          </div>
          {menuToggle && (
            <div className={styles.menuContent}>
              <hr />
              <div onClick={handleMenuToggle}>
                <NavLink
                  to="/admin/home"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.activeClass;
                    }
                    return "";
                  }}
                >
                  Home
                </NavLink>
              </div>
              <div onClick={handleMenuToggle}>
                <NavLink
                  to="/admin/customer-details"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.activeClass;
                    }
                    return "";
                  }}
                >
                  CustomerDetails
                </NavLink>
              </div>
              <div onClick={handleMenuToggle}>
                <NavLink
                  to="/admin/broadband-plans"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.activeClass;
                    }
                    return "";
                  }}
                >
                  BroadbandPartners
                </NavLink>
              </div>
              <div onClick={handleMenuToggle}>
                <NavLink
                  to="/admin/add-plans"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.activeClass;
                    }
                    return "";
                  }}
                >
                  AddNewPlan
                </NavLink>
              </div>
              <div onClick={handleMenuToggle}>
                <NavLink
                  to="/admin/getAll-plans"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.activeClass;
                    }
                    return "";
                  }}
                >
                  GetAllPlans
                </NavLink>
              </div>
              <div onClick={handleMenuToggle}>
                <NavLink
                  to="/admin/general-plans"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.activeClass;
                    }
                    return "";
                  }}
                >
                  GeneralSettings
                </NavLink>
              </div>
              <div onClick={handleMenuToggle}>
                <NavLink
                  to="/admin/logout"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.activeClass;
                    }
                    return "";
                  }}
                >
                  <GrLogout />
                  Logout
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>

      <>
        <Outlet />
        <PageBottom />
      </>
    </>
  );
};

export default Navbar;


