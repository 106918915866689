import { useEffect, useState } from "react";
import styles from "./generalChanges.module.css";
import { useAuth } from "../../../custom hooks/useAuth";
import { useAllPartners } from "../../../custom hooks/useAllPartners";
import BroadbandPlanCard from "../broadbandPlanCard/broadbandPlanCard";
import { usePlan } from "../../../custom hooks/usePlan";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import verifyToken from "../../utility/verify token/verifyToken";

const GeneralChange = () => {
  const [error, setError] = useState(null);
  const [generalChange, setGeneralChange] = useState({
    partner: "",
    ontInstallationCharges: "",
    installationCharges: "",
    staticIPCharges: "",
    color1: "#ffffff",
    color2: "#ffffff",
    colorRange1: "50",
    colorRange2: "50",
  });

  const [selectedValue, setSelectedvalue] = useState("");
  const [generalError, setGeneralError] = useState(null);

  const { allPartners, isLoading } = useAllPartners();
  const { token, logoutUser } = useAuth();
  const { getGeneralData, generalData } = usePlan();

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGeneralChange({ ...generalChange, [name]: value });
    if (name === "partner") {
      setSelectedvalue(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/general/add-changes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(generalChange),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        toast.error(responseData.message);
      } else {
        toast.success(responseData.message);
        setGeneralChange({
          partner: "",
          ontInstallationCharges: "",
          installationCharges: "",
          staticIPCharges: "",
          color1: "#ffffff",
          color2: "#ffffff",
          colorRange1: "50",
          colorRange2: "50",
        });
        await getGeneralData();
      }

      return response;
    } catch (err) {
      setGeneralError(err.message);
    }
  };

  const handleClear = () => {
    window.confirm("Do you want to clear form ?");
    setGeneralChange({
      partner: "",
      ontInstallationCharges: "",
      installationCharges: "",
      staticIPCharges: "",
      color1: "#ffffff",
      color2: "#ffffff",
      colorRange1: "50",
      colorRange2: "50",
    });
  };

  const setGeneralData = () => {
    const partnerId = selectedValue;
    if (generalData.length > 0) {
      const result = generalData.filter((part) => part.partner === partnerId);
      if (result.length > 0) {
        setGeneralChange(result[0]);
      }
    }
  };

  useEffect(() => {
    (async function () {
      await verifyToken(token, setError);
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      logoutUser();
      navigate("/");
    }
  }, [error]);

  useEffect(() => {
    setGeneralData();
  }, [selectedValue]);

  return (
    <>
      {isLoading ? (
        <span style={{ fontSize: "30px" }}>
          <strong>Loading Data...</strong>
        </span>
      ) : (
        <div className={styles.container}>
          <div
            className={styles.addPlanContainer}
            id={styles.generalPlanContainer}
          >
            <h1>Broadband Plan Universal Changes</h1>
            <hr />
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.formElement}>
                <label htmlFor="partnerOptions">Select Partner</label>
                <select
                  id="partnerOptions"
                  name="partner"
                  value={generalChange.partner}
                  onChange={handleInputChange}
                >
                  <option value="">Select Partner...</option>
                  {allPartners.map((partner, i) => (
                    <option value={partner._id} key={i}>
                      {partner.partner}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formElement}>
                <label htmlFor="ontCharges">ONT Installation Charges ₹</label>
                <input
                  type="number"
                  min="0"
                  name="ontInstallationCharges"
                  id="ontCharges"
                  value={generalChange.ontInstallationCharges}
                  onChange={handleInputChange}
                  required
                  placeholder="2999"
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="installationCharges">
                  Installation Charges ₹
                </label>
                <input
                  type="number"
                  min="0"
                  name="installationCharges"
                  id="installationCharges"
                  value={generalChange.installationCharges}
                  onChange={handleInputChange}
                  required
                  placeholder="1599"
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="staticIPCharges">Static IP Charges ₹</label>
                <input
                  type="number"
                  min="0"
                  name="staticIPCharges"
                  id="staticIPCharges"
                  value={generalChange.staticIPCharges}
                  onChange={handleInputChange}
                  required
                  placeholder="4999"
                />
              </div>
              <div className={styles.formElement}>
                <label>Card Background Color </label>
                <div className={styles.range}>
                  <input
                    type="color"
                    name="color1"
                    value={generalChange.color1}
                    onChange={handleInputChange}
                    id={styles.color1}
                    required
                  />
                  <input
                    type="range"
                    min="0"
                    max="100"
                    name="colorRange1"
                    value={generalChange.colorRange1}
                    onChange={handleInputChange}
                    id={styles.range1}
                  />
                </div>
                <div className={styles.range}>
                  <input
                    type="color"
                    name="color2"
                    value={generalChange.color2}
                    onChange={handleInputChange}
                    id={styles.color2}
                    required
                  />
                  <input
                    type="range"
                    min="0"
                    max="100"
                    name="colorRange2"
                    value={generalChange.colorRange2}
                    onChange={handleInputChange}
                    id={styles.range2}
                  />
                </div>
              </div>
              <div className={styles.formbtn}>
                <input
                  type="button"
                  value="CLEAR"
                  id={styles.clearbtn}
                  required
                  onClick={handleClear}
                />
                <input type="submit" id={styles.submitbtn} required />
              </div>
            </form>
          </div>
          <BroadbandPlanCard
            color1={generalChange.color1}
            color2={generalChange.color2}
            colorRange1={generalChange.colorRange1}
            colorRange2={generalChange.colorRange2}
          />
        </div>
      )}
    </>
  );
};

export default GeneralChange;
