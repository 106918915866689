import { useState } from "react";
import styles from "./signin.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../custom hooks/useAuth";
import { MdError } from "react-icons/md";
import { toast } from "react-toastify";
import { GrHide } from "react-icons/gr";

const Signin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [passwordToggle, setPasswordToggle] = useState(true);
  const { storeTokenLS } = useAuth();

  let navigate = useNavigate();

  const handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/user/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        if (response.status == 400) {
          toast.error("Invalid Credentials");
        }
        throw new Error(` ${response.statusText} ${response.status}`);
      } else {
        const responseData = await response.json();
        storeTokenLS(responseData.jwtToken);
        setFormData({ email: "", password: "" });
        navigate("/admin/home");
        toast.success(responseData.message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormData({ ...formData, [name]: value });
    }
    if (name === "password") {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <>
      <div className={styles.container}>
        {error && (
          <div className={styles.errorDiv}>
            <MdError />
            &nbsp;{error}
          </div>
        )}
        <div className={styles.imgContainer}>
          <img src="/images/login.jpg" alt="login" />
        </div>
        <form onSubmit={(e) => handleSubmit(e)} className={styles.form}>
          <div className={styles.formElement} id={styles.login}>
            <label>Login</label>
          </div>
          <div className={styles.formElement}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              id="email"
              placeholder="Enter your email"
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="password">Password</label>
            <input
              type={passwordToggle ? "password" : "text"}
              name="password"
              id="password"
              value={formData.password}
              required
              placeholder="Enter your password"
              onChange={handleInputChange}
            />
            <GrHide
              className={styles.hide}
              onMouseDown={handlePasswordToggle}
              onMouseUp={handlePasswordToggle}
            />
          </div>
          <div className={styles.btn}>
            <input type="Submit" value="Login" readOnly />
          </div>
        </form>
      </div>
    </>
  );
};

export default Signin;
