import ChildOneGetAll from "./childOneGetAll";
import { usePlan } from "../../../custom hooks/usePlan";
import styles from "./getAllPlans.module.css";
import { useAuth } from "../../../custom hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import verifyToken from "../../utility/verify token/verifyToken";

const GetAllPlans = () => {
  const [error, setError] = useState(null);
  const { speedWiseData } = usePlan();

  const { token, logoutUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      await verifyToken(token, setError);
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      logoutUser();
      navigate("/");
    }
  }, [error]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>High Speed Internet Service Plans</h1>
          <p>Choose from various options at an affordable cost.</p>
        </div>

        {speedWiseData.map((partner, i) => (
          <ChildOneGetAll key={i} partner={partner} />
        ))}
      </div>
    </>
  );
};

export default GetAllPlans;
