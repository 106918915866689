import { createContext, useEffect, useState } from "react";
import { useAuth } from "../custom hooks/useAuth";
import { toast } from "react-toastify";

export const partnerContext = createContext();

export const CustomPartnerContext = ({ children }) => {
  const [allPartners, setAllPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addPartnerError, setAddPartnerError] = useState(null);
  const [deletePartnerError, setDeletePartnerError] = useState(null);
  const [getPartnersError, setGetPartnersError] = useState(null);

  const { token } = useAuth();

  const handlePartnerSubmit = async (partner) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/partner/add-partner`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ partner }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        toast.error(responseData.message);
      } else {
        toast.success(responseData.message);
        handleGetAllPartners();
      }
      return response;
    } catch (err) {
      setAddPartnerError(err.message);
    }
  };

  const handleDeletePartner = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/partner/delete-partner/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();

      if (!response.ok) {
        toast.error(responseData.message);
      } else {
        toast.success(responseData.message);
        handleGetAllPartners();
      }
      return response;
    } catch (err) {
      setDeletePartnerError(err.message);
    }
  };

  const handleGetAllPartners = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/partner/get-all-partners`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      if (!response.ok) {
        toast.error(responseData.message);
      } else {
        setAllPartners(responseData);
        setIsLoading(false);
      }
      return response;
    } catch (err) {
      setGetPartnersError(err.message);
    }
  };

  useEffect(() => {
    (async function () {
      if (token) {
        await handleGetAllPartners();
      }
    })();
  }, [token]);

  return (
    <>
      <partnerContext.Provider
        value={{
          allPartners,
          handlePartnerSubmit,
          handleDeletePartner,
          handleGetAllPartners,
          isLoading,
        }}
      >
        {children}
      </partnerContext.Provider>
    </>
  );
};
