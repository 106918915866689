import styles from "./broadbandPlanCard.module.css";

const BroadbandPlanCard = ({ color1, color2, colorRange1, colorRange2 }) => {
  return (
    <>
      <div
        className={styles.container}
        style={{
          background: `linear-gradient(45deg,${color1} ${colorRange1}%,${color2} ${colorRange2}%)`,
        }}
      >
        <h1>Card Background Color</h1>
      </div>
    </>
  );
};

export default BroadbandPlanCard;
