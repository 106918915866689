import { useEffect } from "react";
import { useAuth } from "../../custom hooks/useAuth";
import { Navigate } from "react-router-dom";

const LogoutUser = () => {
  const { logoutUser } = useAuth();
  useEffect(() => {
    logoutUser();
  }, [logoutUser]);

  return <Navigate to="/" />;
};

export default LogoutUser;
