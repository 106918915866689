import { useEffect, useState } from "react";
import styles from "./addPartner.module.css";
import GetAllPartners from "./get all Partners/getAllPartners";
import { useAllPartners } from "../../../custom hooks/useAllPartners";
import { usePlan } from "../../../custom hooks/usePlan";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../custom hooks/useAuth";
import verifyToken from "../../utility/verify token/verifyToken";

const AddPartner = () => {
  const [partner, setPartner] = useState("");
  const [error, setError] = useState(null);

  const { allPartners, handlePartnerSubmit, handleDeletePartner, isLoading } =
    useAllPartners();
  const { getPlans_DB, setSpeedWiseData } = usePlan();
  const { token, logoutUser } = useAuth();
  const navigate = useNavigate();

  //
  const handlePartnerInputChange = (e) => {
    setPartner(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = window.confirm(`Do you want to add ${partner} ?`);
    if (result) {
      const response = await handlePartnerSubmit(partner);
      if (response.ok) {
        setPartner("");
      }
    }
  };

  const handleDelete = async (e, partner, id) => {
    e.preventDefault();
    const result = window.confirm(`Do you want to delete ${partner} ?`);
    if (result) {
      const response = await handleDeletePartner(id);

      await getPlans_DB();
      setSpeedWiseData([]);
    }
  };

  useEffect(() => {
    (async function () {
      await verifyToken(token, setError);
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      logoutUser();
      navigate("/");
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <span style={{ fontSize: "30px" }}>
          <strong>Loading Data...</strong>
        </span>
      ) : (
        <div className={styles.container}>
          <div className={styles.addPartnerContainer}>
            <form onSubmit={handleSubmit}>
              <div className={styles.partner}>
                <label htmlFor="partner">
                  Add Broadband Plan Partner
                  <hr />
                </label>

                <input
                  type="text"
                  name="partner"
                  value={partner}
                  onChange={handlePartnerInputChange}
                  id="partner"
                  placeholder="Please enter partner name"
                  required
                />
              </div>
              <div className={styles.partnerBtn}>
                <input type="submit" value="ADD" id={styles.add} />
              </div>
            </form>
          </div>
          <GetAllPartners
            allPartners={allPartners}
            handleDelete={handleDelete}
          />
        </div>
      )}
    </>
  );
};

export default AddPartner;
