import { useEffect, useState } from "react";
import styles from "./addPlan.module.css";
import { useAllPartners } from "../../../custom hooks/useAllPartners";
import { useAuth } from "../../../custom hooks/useAuth";
import { usePlan } from "../../../custom hooks/usePlan";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import verifyToken from "../../utility/verify token/verifyToken";

const AddPlan = () => {
  const [error, setError] = useState(null);
  const [addPlanError, setAddPlanError] = useState(null);
  const [planData, setPlanData] = useState({
    partner: "",
    speed: "",
    planDuration: "",
    planPrice: "",
  });

  const { allPartners, isLoading } = useAllPartners();
  const { token, logoutUser } = useAuth();
  const { getPlans_DB } = usePlan();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlanData({ ...planData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/plans/add-plan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(planData),
        }
      );
      const responseData = await response.json();

      if (!response.ok) {
        toast.error(responseData.message);
      } else {
        toast.success(responseData.message);
        setPlanData({
          partner: "",
          speed: "",
          planDuration: "",
          planPrice: "",
        });
        getPlans_DB();
      }

      return response;
    } catch (err) {
      setAddPlanError(err.message);
    }
  };

  const handleClear = () => {
    window.confirm("Do you want to clear form ?");
    setPlanData({
      partner: "",
      speed: "",
      planDuration: "",
      planPrice: "",
    });
  };

  useEffect(() => {
    (async function () {
      await verifyToken(token, setError);
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      logoutUser();
      navigate("/");
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <span style={{ fontSize: "30px" }}>
          <strong>Loading Data...</strong>
        </span>
      ) : (
        <div className={styles.container}>
          <div className={styles.addPlanContainer}>
            <h1>Add Broadband Plan</h1>
            <hr />
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.formElement}>
                <label htmlFor="partnerOptions">Select Partner</label>
                <select
                  id="partnerOptions"
                  name="partner"
                  value={planData.partner}
                  onChange={handleInputChange}
                >
                  <option value="">Select Partner...</option>
                  {allPartners.map((part, i) => (
                    <option value={part._id} key={i}>
                      {part.partner}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formElement}>
                <label htmlFor="speed">Enter Broadband Speed</label>
                <input
                  type="text"
                  id="speed"
                  name="speed"
                  value={planData.speed}
                  onChange={handleInputChange}
                  required
                  placeholder="30 Mbps"
                />
              </div>
              <div className={styles.formElement}>
                <label>Enter Plan Duration</label>
                <input
                  type="text"
                  name="planDuration"
                  id="planDuration"
                  value={planData.planDuration}
                  onChange={handleInputChange}
                  placeholder="1 month"
                  required
                />
              </div>
              <div className={styles.formElement}>
                <label htmlFor="price">Enter Plan Price ₹</label>
                <input
                  type="number"
                  min="0"
                  name="planPrice"
                  id="price"
                  value={planData.planPrice}
                  onChange={handleInputChange}
                  required
                  placeholder="499"
                />
              </div>
              <div className={styles.formbtn}>
                <input
                  type="button"
                  value="CLEAR"
                  id={styles.clearbtn}
                  required
                  onClick={handleClear}
                />
                <input type="submit" id={styles.submitbtn} required />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPlan;
