import styles from "./home.module.css";
import SignupPage from "../loginPage/signup";
import { useEffect, useState } from "react";
import { useAuth } from "../../custom hooks/useAuth";
import { useNavigate } from "react-router-dom";
import verifyToken from "../utility/verify token/verifyToken";
import { toast } from "react-toastify";

const Home = () => {
  const [error, setError] = useState(null);
  const { token, logoutUser } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      await verifyToken(token, setError);
    })();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      logoutUser();
      navigate("/");
    }
  }, [error]);

  return (
    <>
      <div className={styles.container}>
        <h1>Welcome to RVSM</h1>
        <h2>Add New Admin/Staff</h2>
        <SignupPage />
      </div>
    </>
  );
};

export default Home;
