import { useState } from "react";
import { useAuth } from "../../../custom hooks/useAuth";
import { usePlan } from "../../../custom hooks/usePlan";
import styles from "./childTwoGetAll.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ChildTwoGetAll = ({ plan, partnerGeneralData }) => {
  const [price, setPrice] = useState("");
  const [toggleInput, setToggleInput] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const { token } = useAuth();
  const { getPlans_DB, setSpeedWiseData } = usePlan();
  const { color1, color2, colorRange1, colorRange2 } = partnerGeneralData[0];
  const navigate = useNavigate();

  const sortedPlan = plan.sort((a, b) => {
    // Extract the numerical part of the strings
    let numA = parseInt(a.planDuration.split(" ")[0]);
    let numB = parseInt(b.planDuration.split(" ")[0]);

    return numA - numB;
  });

  const handlePriceChange = (e, id) => {
    if (id === selectedCardId) {
      setPrice(e.target.value);
    }
  };

  const handleUpdate = async (id) => {
    try {
      if (price && id === selectedCardId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_PATH}/api/plans/update-plan`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id, price }),
          }
        );
        const responseData = await response.json();

        if (!response.ok) {
          toast.error(responseData.message);
        } else {
          toast.success(responseData.message);
          await getPlans_DB();
        }

        return response;
      }
    } catch (err) {
      setUpdateError(err.message);
    }
  };

  const handleInputToggle = () => {
    setToggleInput(!toggleInput);
    setPrice("");
  };

  const deleteIndividualCard = async (id) => {
    const result = window.confirm("Do you want to delete this plan?");
    if (result) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_PATH}/api/plans/delete-plan/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();

        if (!response.ok) {
          toast.error(responseData.message);
        } else {
          toast.success(responseData.message);
          await getPlans_DB();
          setSpeedWiseData([]);
        }

        return response;
      } catch (err) {
        setDeleteError(err.message);
      }
    }
  };

  return (
    <>
      {sortedPlan.map((data, i) => (
        <div
          className={styles.cardContainer}
          style={{
            background: `linear-gradient(45deg,${color1} ${colorRange1}%,${color2} ${colorRange2}%)`,
          }}
          key={i}
        >
          <div className={styles.header}>
            <h2>Speed @{data.speed}</h2>
            <p>1/3/6/12 months plans available for new customers</p>
            <h3>
              Select Duration&nbsp;&nbsp; <span>{data.planDuration}</span>
            </h3>
          </div>
          <hr />
          <div className={styles.cardBody}>
            <p id={styles.price1}>Price</p>
            <p id={styles.data1}>Data</p>
            <p id={styles.price2}>₹ {data.planPrice}</p>
            <p id={styles.data2}>Unlimited @{data.speed}</p>
          </div>
          <div className={styles.footer}>
            <div>
              {toggleInput && data._id === selectedCardId && (
                <input
                  type="text"
                  placeholder="Enter new price"
                  name="planPrice"
                  value={price}
                  onChange={(e) => handlePriceChange(e, data._id)}
                  autoFocus
                />
              )}
              <button
                onClick={() => {
                  handleUpdate(data._id);
                  handleInputToggle();
                  setSelectedCardId(data._id);
                }}
              >
                Update
              </button>
              <button onClick={() => deleteIndividualCard(data._id)}>
                Delete
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ChildTwoGetAll;
