import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CustomAuthContext } from "./store/authContext.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomPartnerContext } from "./store/partnerContext.js";
import { CustomPlanContext } from "./store/planContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CustomAuthContext>
      <CustomPartnerContext>
        <CustomPlanContext>
          <App />

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </CustomPlanContext>
      </CustomPartnerContext>
    </CustomAuthContext>
  </React.StrictMode>
);
