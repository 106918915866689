import { useEffect, useState } from "react";
import styles from "./signup.module.css";
import { toast } from "react-toastify";
import { GrHide } from "react-icons/gr";
import { useAuth } from "../../custom hooks/useAuth";
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    type: "",
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [isDisabled, setIsDisabled] = useState(true); // State to control form disable/enable
  const [passwordToggle, setPasswordToggle] = useState(true);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(true);
  const [error, setError] = useState(null);
  const { token, logoutUser } = useAuth();
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsDisabled(!isDisabled); // Toggle form disable/enable
  };
  const handleOnChange = (e) => {
    setSelectedOption(e.target.value);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = window.confirm("Do you want to submit this form ?");
    if (result) {
      if (formData.password === formData.confirmPassword) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_PATH}/api/user/signup`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(formData),
            }
          );
          const responseData = await response.json();
          if (response.ok) {
            toast.success(responseData.message);
            setFormData({
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              type: "",
            });
          } else {
            toast.error(responseData.message);
          }
        } catch (err) {
          setError(err.message);
        }
      } else {
        toast.error("Password and Confirm password field does not matched.");
      }
    }
  };

  const handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };
  const handleConfirmPasswordToggle = () => {
    setConfirmPasswordToggle(!confirmPasswordToggle);
  };

  const handleClear = () => {
    setFormData({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      type: "",
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      logoutUser();
      navigate("/");
    }
  }, [error]);
  return (
    <>
      <div className={styles.container}>
        <button onClick={handleToggle} className={styles.toggleBtn}>
          {isDisabled ? "Enable Form" : "Disable Form"}
        </button>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className={styles.form}
          autoComplete="off"
        >
          <div className={styles.formElement}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleOnChange}
              required
              placeholder="Enter name"
              disabled={isDisabled}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleOnChange}
              id="email"
              placeholder="Enter email"
              autoComplete="off"
              disabled={isDisabled}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="password">Password</label>
            <input
              type={passwordToggle ? "password" : "text"}
              name="password"
              id="password"
              value={formData.password}
              onChange={handleOnChange}
              required
              placeholder="Enter password"
              disabled={isDisabled}
            />
            <GrHide
              className={styles.hide}
              onMouseDown={handlePasswordToggle}
              onMouseUp={handlePasswordToggle}
            />
          </div>
          <div className={styles.formElement}>
            <label htmlFor="confirm password">Confirm Password</label>
            <input
              type={confirmPasswordToggle ? "password" : "text"}
              name="confirmPassword"
              id="confirm password"
              value={formData.confirmPassword}
              onChange={handleOnChange}
              required
              placeholder="Confirm password"
              disabled={isDisabled}
            />
            <GrHide
              className={styles.hide}
              onMouseDown={handleConfirmPasswordToggle}
              onMouseUp={handleConfirmPasswordToggle}
            />
          </div>
          <div className={styles.formElement}>
            <label>Type</label>
            <div>
              <input
                type="radio"
                name="type"
                value="admin"
                id="admin"
                onChange={handleOnChange}
                checked={selectedOption === "admin"}
              />
              <label htmlFor="admin">admin</label>
              <input
                type="radio"
                name="type"
                value="staff"
                id="staff"
                onChange={handleOnChange}
                checked={selectedOption === "staff"}
              />
              <label htmlFor="staff">staff</label>
            </div>
          </div>
          <div className={styles.btn}>
            <input type="Submit" />
            <input
              type="button"
              value="Clear"
              onClick={handleClear}
              style={{ backgroundColor: "black", marginRight: "10px" }}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default SignupPage;
