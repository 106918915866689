import { useState } from "react";
import { usePlan } from "../../../custom hooks/usePlan";
import ChildTwoGetAll from "./childTwoGetAll";
import styles from "./childOneGetAll.module.css";

const ChildOneGetAll = ({ partner }) => {
  const [toggleDisplay, setToggleDisplay] = useState(false);
  const temp1 = partner[0];
  const temp2 = temp1[0];
  const { generalData } = usePlan();

  let partnerGeneralData = generalData.filter(
    (part) => part.partner === temp2.partner._id
  );

  //
  if (partnerGeneralData.length < 1) {
    partnerGeneralData = [
      {
        ontInstallationCharges: "",
        installationCharges: "",
        staticIPCharges: "",
        color1: "#ffffff",
        color2: "#ffffff",
        colorRange1: "50",
        colorRange2: "50",
      },
    ];
  }

  const handleToggle = () => {
    setToggleDisplay(!toggleDisplay);
  };

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.header} onClick={handleToggle}>
          FTTH PLANS WITH {temp2.partner.partner}
        </h2>
        {toggleDisplay && (
          <>
            <div className={styles.cardContainer}>
              {partner.map((plan, i) => (
                <ChildTwoGetAll
                  key={i}
                  plan={plan}
                  partnerGeneralData={partnerGeneralData}
                />
              ))}
            </div>
            <div className={styles.staticContainer}>
              {" "}
              <p>
                <strong>Static IP Charges Yearly :</strong> ₹{" "}
                {partnerGeneralData[0].staticIPCharges}
                /-
              </p>
              <p>
                <strong>*WI-FI ONT :</strong> ₹{" "}
                {partnerGeneralData[0].ontInstallationCharges}/- (One Time,
                Non-refundable)
              </p>
              <p>
                <strong>Installation Charges :</strong> ₹{" "}
                {partnerGeneralData[0].installationCharges}
                /-
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ChildOneGetAll;
