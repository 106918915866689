import { createContext, useState } from "react";

export const authContext = createContext();

export const CustomAuthContext = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const storeTokenLS = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
  };
  let isLoggedIn = !!token;
  const logoutUser = () => {
    setToken("");
    localStorage.removeItem("token");
  };

  return (
    <>
      <authContext.Provider
        value={{ storeTokenLS, logoutUser, isLoggedIn, token }}
      >
        {children}
      </authContext.Provider>
    </>
  );
};
