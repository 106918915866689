const verifyToken = async (token, setError) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_PATH}/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`${response.statusText} ${response.status}`);
    }
  } catch (err) {
    setError(err.message);
  }
};

export default verifyToken;
